import React from 'react';
import { Button, DialogActions, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import AccountIcon from '@material-ui/icons/AccountCircleRounded';
import { APP_TEXTS } from '../config/app.texts';

const cancelDialog = {
	background: 'rgb(201,28,123)',
	backgroundImage: 'linear-gradient(0deg, rgba(201,28,123,1) 0%, rgba(228,58,43,1) 100%)',
	padding: '4% ​4% 2% 4%',
	color: '#ffffff',
};

const DialogImportantInfo = ({ handleConfirmDialog }) => (
	<>
		<DialogTitle style={cancelDialog}>
			<Grid style={{ display: 'flex', margin: '0%'}}>
				<Grid xs={2}>
					<InfoIcon style={{ fontSize: '3rem', marginTop: '1%' }} />
				</Grid>
				<Grid xs={10}>
					<Typography
						variant="h6"
						style={{ paddingTop: '3%' }}
					>
						{ APP_TEXTS.INFO_IMPORTANT_TITLE_MESSAGE }
					</Typography>
				</Grid>
			</Grid>
		</DialogTitle>
		<DialogContentText style={{ padding: '5%', margin: '0% 0% 5% 0%', textAlign: 'center', color: '#000000' }}>
			<AccountIcon style={{ fontSize: '4rem', marginRight: '1%' }} />
			<Typography variant="h4" component="h4" gutterBottom style={{ fontSize: '1.8rem' }}>
				{APP_TEXTS.USER_BELONGS_ANOTHER_HIERARCHY}
			</Typography>
			<Typography variant="h6" component="h6" gutterBottom style={{ paddingTop: '2%' }}>
				{APP_TEXTS.USER_BELONGS_ANOTHER_HIERARCHY_SUBTITLE}
			</Typography>
		</DialogContentText>
		<DialogActions>
			<Button
				className="Button redGradient"
				onClick={handleConfirmDialog}
				color="default"
			>
				{APP_TEXTS.ACCEPT}
			</Button>
		</DialogActions>
	</>
);

export default DialogImportantInfo;
