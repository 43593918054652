/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import APP_CONFIG from '../config/app.config';
import UserProfile from './UserProfile';
import vibraLogo from '../images/vibra_logo.png';

// Definition
class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			date: '00:00:00',
		};
		this.currentDateId = null;
	}

	componentDidMount() {
		this.updateDate();
	}

	updateDate = () => {
		const timeout = 1000;
		let h = new Date().getHours();
		let m = new Date().getMinutes();
		let s = new Date().getSeconds();
		h = (h >= 10) ? h : `0${h}`;
		m = (m >= 10) ? m : `0${m}`;
		s = (s >= 10) ? s : `0${s}`;
		const date = `${h}:${m}:${s}`;
		this.setState({ date });

		this.currentDateId = setTimeout(() => {
			clearTimeout(this.currentDateId);
			this.currentDateId = null;

			this.updateDate();
		}, timeout);
	}

	render() {
		const { date } = this.state;
		return (
			<>
				<header className="Header">
					<div className="contents">
						<div id="logo" className="Logo">
							<div className="Internal CenteredY">
								<img src={vibraLogo} alt={APP_CONFIG.COMPANY_NAME} />
							</div>
						</div>
						<div className="Date">
							{date}
						</div>
						<UserProfile />
					</div>
				</header>
			</>
		);
	}
}

export default Header;
