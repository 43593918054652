/* eslint-disable react/destructuring-assignment */
// User Profile
import React from 'react';
import {
	Button,
	TextField,
	Collapse,
	LinearProgress,
	Paper,
	Card,
	CardHeader,
	CardContent,
	CircularProgress,
	Grid,
	ThemeProvider,
	InputAdornment,
	IconButton,
	Box,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { connect } from 'react-redux';
import { setUserData, clearUserData } from '../redux/action_types';
import APP_CONFIG from '../config/app.config';
import { APP_TEXTS } from '../config/app.texts';
import theme from '../Components/ColorTheme';
import { APP_UTILS } from '../config/app.utils';

// Definition
class Login extends React.Component {
	constructor(props) {
		super(props);

		// Define initial state
		this.initialState = {
			autoLoginProcess: false,
			email: '',
			error: {
				type: 'info',
				message: '',
			},
			loginProcess: false,
			password: '',
			token: '',
			showPassword: false,
		};

		this.componentEnabled = true;
		this.emailInput = null;
		this.state = this.initialState;
	}

	componentDidMount() {
		// Check if user is loaded or not
		const storageData = localStorage.getItem(APP_CONFIG.API_USER_COOKIE);
		if (!storageData) {
			return;
		}

		// Validate data
		let userData;
		try {
			userData = JSON.parse(storageData);
		} catch (e) {
			// No valid data - clear it
			localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');
			return;
		}

		if (userData && userData.email && userData.token) {
			// Set auto login process
			this.setState(
				{
					email: userData.email,
					password: '',
					token: userData.token,
					autoLoginProcess: true,
				},
				this.processForm
			);
		} else {
			// No valid data - clear it
			localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');
		}
	}

	componentWillUnmount() {
		this.componentEnabled = false;
	}

	onKeyPress = (e) => {
		// Get input field
		switch (e.target.id) {
			case 'email':
				if (e.key === 'Enter' && this.state.password !== '') {
					this.processForm();
				}
				break;

			case 'password':
				if (e.key === 'Enter') {
					this.processForm();
				}
				break;

			default:
				break;
		}
	};

	handleLogin = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.processForm();
	};

	handleForgotPassword = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.history.push('/forgotpassword');
	};
	
	async getPointsOfSale(operatorId, token) {
		let pointsOfSaleData = this.getPointsOfSaleData(false);
		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_POINTS_SALE;

		const params = JSON.stringify({
			"operator_id": operatorId,
		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					default:
						// Another status, send unexpected error
						this.processErrors(0);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					if (errorCode === 'AUTH_INVALID') {
						pointsOfSaleData = this.getPointsOfSaleData(false);
					} else {
						this.processErrors(errorCode);
					}
					return;
				}

				if (resp) {
					pointsOfSaleData = this.getPointsOfSaleData(resp);
				}
			})
			.catch((error) => {
				console.log(error);
			});

		return pointsOfSaleData;
	}
	
	async getPrizes(operatorId, token) {
		let prizesData = this.processGetPrizes(false);
		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_GET_PRIZES;

		const params = JSON.stringify({
			"operator_id": operatorId,
		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.props.history.push('/');
						break;

					default:
						// Another status, send unexpected error
						this.processErrors(0);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}

				if (resp && resp.data ) {
					prizesData = this.processGetPrizes(resp.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		
		return prizesData;
	}

	showHidePassword = () => {
		const statePass = this.state.showPassword;
		this.setState({ showPassword: !statePass });
	};

	processGetPrizes = (response) => {
		const prizeMap = [];
		const data = response;
		// eslint-disable-next-line no-restricted-syntax
		for (const key in data) {
			if (Object.hasOwnProperty.call(data, key)) {
				const prize = {
					id: Number(key),
					type: data[key],
				};
				prizeMap.push(prize);	
			}
		}

		return prizeMap;
	};

	getPointsOfSaleData = (data) => {
		const pointsOfSale = (data && data.length) ? data : [];

		return pointsOfSale;
	};

	processErrors = (errorCode) => {
		// Check for errors
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}

		this.setState({ loginProcess: false });
	};

	setErrorMessageOnForm = (errorType, errorText) => {
		if (this.state.autoLoginProcess) {
			this.logoutUser();
		} else {
			this.setMessage(errorType, errorText);
			this.setState({ loginProcess: false });
		}
	};

	setMessage = (type, message) => {
		if (type && message) {
			// Set Error
			this.setState({
				error: { type, message },
			});
		}
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	async processResponse(data) {
		if (!this.componentEnabled) {
			return;
		}

		// Check status for errors
		if (data.errors && data.errors.length) {
			if (this.state.autoLoginProcess) {
				this.logoutUser();
				return;
			}

			// Get Error code
			const errorCode = data.errors[0] && data.errors[0].code ? data.errors[0].code : 0;
			this.processErrors(errorCode);
			return;
		}

		// Prepare data to save
		const userData = {
			logged: true,
			firstName: data.first_name,
			lastName: data.last_name,
			email: this.state.email,
			operators: data.operators,
			operatorId: (data.operators.length) ? data.operators[0].id : 0,
			operatorName: (data.operators.length) ? data.operators[0].name : 0,
			operatorFeatures: (data.operators.length) ? data.operators[0].features : {},
			token: data.token,
			firstLogin: data.first_login,
		};

		// Set cookie
		localStorage.setItem(APP_CONFIG.API_USER_COOKIE, JSON.stringify(userData));

		// Clear data and close login
		this.setMessage('success', APP_TEXTS.LOGIN_SUCCESSFUL);

		const getPointsOfSale = this.getPointsOfSale(userData.operatorId, userData.token);
		const getPrizes = this.getPrizes(userData.operatorId, userData.token);

		const value = await Promise.all([getPointsOfSale, getPrizes]);
		userData.pointsOfSale = (value && value[0]) || [];
		userData.prizesData = (value && value[1]) || [];

		// Save it to the store
		this.props.setUserData(userData);
	};

	async processForm() {
		// Double check that the login process is not already running
		if (!this.componentEnabled || this.state.loginProcess) {
			return false;
		}

		const validation = (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)));
		if (validation) {
			this.setMessage('warning', APP_TEXTS.REQUIRED_INVALID_EMAIL);
			return false;
		}

		// Read user and password
		if (!this.state.autoLoginProcess && (!this.state.email || !this.state.password)) {
			this.emailInput.focus();
			this.setMessage('info', APP_TEXTS.COMPLETE_FORM);
			return false;
		}

		// Set Form on process and clear previous error
		this.setState({
			loginProcess: true,
			error: { type: 'info', message: '' },
		});

		// Get data
		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += this.state.autoLoginProcess
			? APP_CONFIG.API_ENDPOINT_VALIDATE
			: APP_CONFIG.API_ENDPOINT_LOGIN;
		requestUrl += `?hash=${new Date().getTime()}`;

		// Send Data
		const data = new FormData();
		if (!this.state.autoLoginProcess) {
			data.append('email', this.state.email);
			data.append('password', this.state.password);
		}

		// Set up Headers for validation
		let headers = {};
		if (this.state.autoLoginProcess) {
			headers = new Headers({
				'Content-Type': 'application/json',
				'Authorization': `JWT ${this.state.token}`,
			});
		}
		await fetch(requestUrl, {
			method: 'POST',
			body: data,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.setErrorMessageOnForm('error', APP_TEXTS.UNAUTHORIZED_ACCESS);
						break;

					default:
						// Another status, send unexpected error
						this.setErrorMessageOnForm('error', APP_TEXTS.CONNECTION_ERROR);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then(
				(returnedData) => {
					this.processResponse(returnedData);
				},
				() => {
					// this.setErrorMessageOnForm('error', APP_TEXTS.RETURNED_DATA_ERROR);
				}
			)
			.catch(() => {
				this.setErrorMessageOnForm('error', APP_TEXTS.UNEXPECTED_ERROR);
			});

		return true;
	}

	// Logo out / clear all user data
	logoutUser() {
		// Clear User Data
		this.setState(this.initialState);

		// Clear local storage
		localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');

		// Update store
		this.props.clearUserData();
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<Paper
					className="Home Main CenteredX no-border"
					variant="outlined"
					elevation={0}
					style={{ backgroundColor: 'transparent' }}
				>
					{this.state.autoLoginProcess && (
						<Card className="Card Login CenteredXY" variant="outlined" elevation={4}>
							<CardContent>
								<Grid container className="relative h100">
									<Grid
										item
										xs={2}
										className="relative h100 alignSelfCenter textAlignRight"
									>
										<CircularProgress color="secondary" />
									</Grid>
									<Grid
										item
										xs={10}
										className="relative h100 alignSelfCenter textAlignCenter"
									>
										{APP_TEXTS.VALIDATING_DATA}
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					)}
					{!this.state.autoLoginProcess && (
						<Card
							className="Card Login CenteredXY"
							variant="outlined"
							elevation={4}
							xs={12}
						>
							<Box className='LogoLogin' />
							<CardHeader
								title="Login"
								subheader="Ingrese sus credenciales"
								avatar={<LockIcon />}
							/>
							<CardContent style={{ paddingBottom: '10px' }}>
								<div>
									<TextField
										autoFocus
										margin="dense"
										id="email"
										label="Usuario"
										type="text"
										variant="outlined"
										fullWidth
										value={this.state.email}
										inputRef={(input) => {
											this.emailInput = input;
										}}
										onKeyPress={this.onKeyPress}
										onChange={(event) =>
											this.setState({ email: event.currentTarget.value })
										}
									/>
									<TextField
										id="password"
										label="Contraseña"
										type={this.state.showPassword ? 'text' : 'password'}
										margin="dense"
										variant="outlined"
										fullWidth
										value={this.state.password}
										onKeyPress={this.onKeyPress}
										onChange={(event) =>
											this.setState({
												password: event.currentTarget.value,
											})
										}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={this.showHidePassword}
													>
														{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<Collapse in={this.state.error.message !== ''}>
										<Alert
											className="Alert"
											onClose={this.clearError}
											severity={this.state.error.type}
										>
											{this.state.error.message}
										</Alert>
									</Collapse>
									{this.state.loginProcess && <LinearProgress />}
								</div>
								<Grid
									container
									xs={12}
									spacing={2}
									style={{ paddingTop: '15px', justifyContent: 'space-between' }}
								>
									<Grid
										item
										sm={6}
										xs={12}
									>
										<Button
											fullWidth
											variant="outlined"
											className="Button ButtonSmall"
											onClick={this.handleForgotPassword}
											disabled={this.state.loginProcess}
										>
											{APP_TEXTS.BTN_FORGOT_PASS}
										</Button>
									</Grid>
									<Grid
										item
										sm={6}
										xs={12}
									>
										<Button
											type="submit"
											fullWidth
											onClick={this.handleLogin}
											className="Button ButtonSmall greenGradient"
											disabled={this.state.loginProcess}
										>
											{APP_TEXTS.BTN_ENTER}
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					)}
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

const mapDispatchToProps = {
	setUserData,
	clearUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
