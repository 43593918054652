/* eslint-disable no-param-reassign */
import { APP_TEXTS } from "./app.texts";

const APP_UTILS = {
	formatCurrency: (number) => {
		const formattedNumber = new Intl.NumberFormat('es-EC', { style: 'currency', currency: 'USD' }).format(number);
		return `${formattedNumber}`;
	},

	formatDate: (dateData) => {
		const year = new Date(dateData).getFullYear();
		let month = new Date(dateData).getMonth() + 1;
		let day = new Date(dateData).getDate();
		month = (month >= 10) ? month : `0${month}`;
		day = (day >= 10) ? day : `0${day}`;

		const dateParsed = `${day}-${month}-${year}`;
		return dateParsed;
	},

	getErrorByCode: (errorCode) => {
		const error = {
			type: 'error',
			server: errorCode,
			message: APP_TEXTS.UNEXPECTED_ERROR,
		};

		switch (errorCode) {
			case 0:
			case 'METHOD_NOT_EXIST':
			case 'ERROR_IN_METHOD':
			case 'OPERATOR_UNREACHABLE':
				error.message = APP_TEXTS.CONNECTION_ERROR;
				break;
			
			case 500:
				error.message = APP_TEXTS.INTERNAL_SERVER_ERROR;
				break;
			
			case 'INVALID_ARGUMENTS':
			case 'ARGUMENTS_TYPE':
				error.type = 'warning';
				error.message = APP_TEXTS.INVALID_DATA;
				break;
			
			case 'WALLET_ERROR':
				error.message = APP_TEXTS.ERROR_CONNECTION_WALLET;
				break;

			case 'OPERATOR_ERROR':
			case 'OPERATOR_NOT_FOUND':
				error.message = APP_TEXTS.OPERATOR_NOT_FOUND;
				break;
			
			case 'ERROR_IN_RESPONSE':
				error.message = APP_TEXTS.ERROR_IN_RESPONSE;
				break;

			case 'USER_NOT_FOUND':
				error.message = APP_TEXTS.INVALID_USER_PASSWORD;
				break;

			case 'INCORRECT_OLD_PASWORD':
				error.message = APP_TEXTS.INVALID_OLD_PASSWORD;
				break;
			
			case 'REQUIRED_NEW_PASSWORD':
				error.message = APP_TEXTS.REQUIRED_NEW_PASSWORD;
				break;
			
			case 'TYPE_DOC_NOT_EXIST':
				error.message = APP_TEXTS.TYPE_DOC_NOT_EXIST;
				break;
			
			case 'POS_NOT_FOUND':
			case 'POS_OPERATOR_NOT_FOUND':
			case 'POS_OPERATOR_RELATION_NOT_FOUND':
				error.message = APP_TEXTS.POS_OPERATOR_NOT_FOUND;
				break;
			
			case 'CANNOT_GENERATE_PDF':
				error.message = APP_TEXTS.CANNOT_GENERATE_PDF;
				break;

			case 'MAIL_SEND_FAIL':
			case 'CANNOT_SEND_MAIL':
				error.message = APP_TEXTS.MAIL_SEND_FAIL;
				break;
			
			case 'DOC_NOT_EXIST':
			case 'PERSON_NOT_FOUND':
				error.type = 'info';
				error.message = APP_TEXTS.UNREGISTERED_PERSON_MAIL;
				break;

			case 'TRANSACTION_STATE_INVALID':
			case 'TRANSACTION_STATE_NOT_VALID':
				error.message = APP_TEXTS.TRANSACTION_STATE_INVALID;
				break;

			case 'ERROR_IN_TRANSACTION':
				error.message = APP_TEXTS.ERROR_IN_TRANSACTION;
				break

			case 'TRANSACTION_TYPE_INVALID':
			case 'TRANSACTION_TYPE_NOT_FOUND':
				error.message = APP_TEXTS.TRANSACTION_TYPE_NOT_FOUND;
				break;

			case 'TRANSACTION_NOT_FOUND':
				error.message = APP_TEXTS.TICKET_NUMBER_NOT_EXIST;
				break;
			
			case 'TRANSACTION_TOKEN_MISSING':
				error.message = APP_TEXTS.REQUIRED_TICKET_NUMBER;
				break;

			case 'MATCH_OUT_OF_DATE':
				error.type = 'warning';
				error.message = APP_TEXTS.OUT_PURCHASE_DATE;
				break;

			case 'PAY_OUT_OF_DATE':
				error.message = APP_TEXTS.PAY_OUT_OF_DATE;
				break;

			case 'PRIZE_OUT_OF_RANGE':
				error.message = APP_TEXTS.PRIZE_OUT_OF_RANGE;
				break;

			case 'PRIZE_ALREADY_REGISTRED':
				error.message = APP_TEXTS.PRIZE_ALREADY_REGISTRED;
				break;

			case 'PRIZE_ALREADY_PAYED':
				error.message = APP_TEXTS.PRIZE_ALREADY_PAYED;
				break;
		
			case 'CREDIT_OUT_OF_RANGE':
				error.message = APP_TEXTS.CREDIT_OUT_OF_RANGE;
				break;

			default:
				break;
		}

		return error;
	},
};

// eslint-disable-next-line import/prefer-default-export
export { APP_UTILS };
