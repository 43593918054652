const APP_TEXTS = {
	/* SECTIONS AND TITLES */
	BUY_CARD: 'Compra de Cartones',
	BUY_CREDIT: 'Agregado de Crédito',
	PAY_PRIZE: 'Pago de Premio',
	BALANCES : 'Reportes',
	CREDIT_WITHDRAWAL : 'Retiro de Crédito',
	COMMISSION: 'Comisión',

	/* BUTTONS */
	PAY_TICKET: 'Pagar Premio',
	CASHOUT_CREDIT: 'Retirar Crédito',
	BACK: 'Volver',
	GO_TO_MENU: 'Ir a Menú',
	CANCEL: 'Cancelar',
	ACCEPT: 'Aceptar',
	BTN_CASHOUT: 'Retirar',
	BTN_PAY: 'Pagar',
	BTN_CLOSE: 'Cerrar',
	BTN_DELETE: 'Borrar',
	BTN_CONTINUE: 'Continuar',
	BTN_VALIDATE: 'Validar',
	BTN_SEARCH: 'Buscar',
	BTN_FORGOT_PASS: 'Olvidé contraseña',
	BTN_ENTER: 'Ingresar',
	BTN_DOWNLOAD_WITHHOLDINGS: 'Descargar Plantilla de retención',

	/* API ERRORS */
	CONNECTION_ERROR: 'No se pudo conectar con el servidor!',
	UNEXPECTED_ERROR: 'Error inesperado! Por favor intente nuevamente',
	INVALID_DATA: 'Datos inválidos!. Por favor intente nuevamente!',
	OPERATOR_NOT_FOUND: 'Error. No se puedo encontrar o conectar con el Operador!',
	INVALID_USER_PASSWORD: 'Usuario o contraseña incorrectos!',
	INVALID_OLD_PASSWORD: 'La contraseña anterior es incorrecta',
	TYPE_DOC_NOT_EXIST: 'Tipo de Documento no existe',
	POS_OPERATOR_NOT_FOUND: 'ERROR. No se pudo encontrar o conectar con el Punto de Venta!',
	CANNOT_GENERATE_PDF: 'Error al generar el PDF. Por favor, reenvie el correo electrónico al cliente.',
	MAIL_SEND_FAIL: 'Error al enviar de correo electrónico. Por favor, reenvie el correo electrónico al cliente.',
	UNREGISTERED_PERSON: 'Cliente no registrado.',
	UNREGISTERED_PERSON_MAIL: 'DNI no encontrado. Ingresar correo electrónico para asociar a este DNI.',
	ERROR_IN_TRANSACTION: 'Error al procesar la operación. Por favor intente nuevamente!',
	ERROR_IN_RESPONSE: 'Error en la respuesta del servidor. Por favor intente nuevamente!',
	TRANSACTION_STATE_INVALID: 'Error. Estado de Transacción inválido',
	TRANSACTION_TYPE_NOT_FOUND: 'Error. Tipo de Transacción inválido',
	TICKET_NUMBER_NOT_EXIST: 'Código de ticket no existe. Por favor valide e intente nuevamente!',
	ERROR_CONNECTION_WALLET: 'No se pudo conectar con la Billetera!',
	OUT_PURCHASE_DATE: 'El sorteo se encuentra cerrado para la compra',
	PAY_OUT_OF_DATE: 'No es posible pagar este premio. Premio Caducado!',
	PRIZE_OUT_OF_RANGE: 'Pago fuera de Rango. El pago del premio será realizado por el IAFAS',
	PRIZE_ALREADY_REGISTRED: 'El premio ya se encuentra registrado.',
	PRIZE_ALREADY_PAYED: 'Disculpe, el premio ya fue pagado!',
	CREDIT_OUT_OF_RANGE: 'Compra de crédito fuera de rango',
	INTERNAL_SERVER_ERROR: 'Error inesperado del servidor. Código del Error: 500',
	
	/* COMMON */
	UNAUTHORIZED_ACCESS: 'Usuario no autorizado. Por favor vuelva a ingresar.',
	RETURNED_DATA_ERROR: 'Error en los datos. Por favor intente nuevamente',
	COMPLETE_FORM: 'Por favor complete el formulario!',
	VALIDATING_DATA: 'Validando datos...',
	VALIDATING_TOKEN: 'Validando token...',
	ENTER_EMAIL: 'Ingrese su correo electrónico!',
	ENTER_PASSWORD: 'Ingrese su password!',
	ENTER_NEW_PASSWORD_CONFIRMATION: 'Ingrese confirmación de nueva contraseña',
	ENTER_OLD_PASSWORD: 'Ingrese contraseña anterior',
	ENTER_NEW_PASSWORD: 'Ingrese nueva contraseña',
	PASSWORD_DO_NOT_MATCH: 'Las contraseñas no son iguales',
	PASSWORD_TOKEN_EXPIRED: 'El token de cambio de contraseña expiró.',
	RESET_PASSWORD: 'Elige una nueva contraseña',
	CHANGE_PASSWORD_NEEDED: 'Debes cambiar tu contraseña en el primer acceso',
	CHANGE_PASSWORD_SUCCESSFUL: 'Cambio de contraseña exitoso!',
	LOGIN_SUCCESSFUL: 'Login exitoso!',
	SESSION_EXPIRED: 'Sesión Expirada',
	SESSION_EXPIRED_MESSAGE: 'Por medidas de seguridad debe iniciar sesión nuevamente!',
	EMAIL_SENT: 'Se ha enviado un mail a su cuenta de correo!',
	EMAIL_TOKEN_SENT: 'Ingrese el token de seguridad enviado a su cuenta de correo!',
	TOTAL_CREDIT: 'Total Crédito',
	CREDIT_TO_LOAD: 'Crédito a Cargar',
	PLAYER_PERSONAL_DATA: 'Datos del Cliente',
	NO_RAFFLE_FOUND: 'No se encontraron sorteos para este día',
	CARD_ID_LABEL: 'Número de Comprobante',
	MAIL_LABEL: 'Correo Electrónico:',
	DATE_PURCHASE_LABEL: 'Fecha de Compra:',
	DATE_RAFFLE_LABEL: 'Fecha de sorteo: ',
	HOUR_RAFFLE_LABEL: 'Hora de sorteo: ',
	CARD_VALUE_LABEL: 'Valor del cartón: ',
	RAFFLE_NAME_LABEL: 'Nombre del sorteo: ',
	TOTAL_PRIZE_LABEL: 'Premio Total:',
	TOTAL_PAY_LABEL: 'Total a Pagar:',
	TYPE_LABEL: 'Tipo',
	DNI_LABEL: 'DNI',
	PASS_LABEL: 'Pasaporte',
	LC_LABEL: 'Libreta Cívica',
	LE_LABEL: 'Libreta de Enrolamiento',

	/* MESSAGES */
	TITLE_MESSAGE_CONFIRMATION: 'Mensaje de Confirmación',
	MESSAGE_RETRY: 'Reintentar',
	MESSAGE_CHANGE_PASSWORD: 'Cambiar Contraseña',
	MESSAGE_PASSWORD_LENGTH: 'La Contraseña debe contener al menos 8 caracteres!',
	MESSAGE_OPERATOR: 'Operador',
	MESSAGE_MY_POS: 'Mis Puntos de Ventas',
	MESSAGE_LOGOUT: 'Desloguearse',
	SUCCESS_TITLE_MESSAGE: 'Operación Exitosa',
	CANCEL_TITLE_MESSAGE: 'Operación Cancelada',
	INFO_IMPORTANT_TITLE_MESSAGE: 'Información Importante',
	USER_BELONGS_ANOTHER_HIERARCHY: 'Este cliente se encuentra asociado a otro agente.',
	USER_BELONGS_ANOTHER_HIERARCHY_SUBTITLE: 'Cualquier operación contabilizará comisión para el agente que tiene asociado el cliente.',
	TICKET_NUMBER_NOT_PRIZE: 'Código de ticket sin premio para abonar',
	CONFIRM_MESSAGE_CASHOUT: '¿Desea retirar {amount} de crédito?',
	CONFIRM_CLOSE_TRANSACTIONS: '¿Desea cerrar todas las operaciones?',
	CONFIRM_MESSAGE_PRIZE: '¿Desea pagar el premio?',
	PIN: 'Por favor, Ingrese el número de Pin!',
	PRIZE_PAYED: 'El ticket fue pagado anteriormente. Número de Comprobante de Pago: ',
	SUCCESS_TRANSACTION: 'La operación fue procesada con éxito',
	CANCEL_TRANSACTION: 'La operación no fue procesada',
	CLOSURE_TRANSACTION: 'La operación fue cerrada de manera éxitosa',

	/* REQUIREDS */
	REQUIRED_OLD_PASSWORD: 'Contraseña anterior requerida!',
	REQUIRED_NEW_PASSWORD: 'nueva contraseña requerida!',
	REQUIRED_DOC: 'Número de Documento requerido!',
	REQUIRED_INVALID_DOC: 'Por favor, Ingrese un número de documento válido!',
	REQUIRED_EMAIL: 'Se requiere un correo electrónico para continuar',
	REQUIRED_INVALID_EMAIL: 'Por favor, Ingrese un correo electrónico válido',
	REQUIRED_TICKET_NUMBER: 'Número de Ticket requerido!',
	REQUIRED_PIN: 'Número de Pin requerido!',
	REQUIRED_MIN_CREDIT: 'El crédito mínimo permitido es {amount}',
	REQUIRED_TOKEN_SENT: 'Por favor, Ingrese el token de seguridad enviado a su cuenta de correo!',

	/* REPORTS AND CLOSE TRANSACTIONS */
	NOT_REPORT_FOUND: 'No se encontraron resultados',
	SEARCHING: 'Buscando...',
	CLOSE_TRANSACTIONS: 'Cerrar Operaciones',
	CLOSED_TRANSACTIONS: 'Operaciones cerradas de manera exitosa!',
	CLOSING_TRANSACTIONS: 'Cerrando Operaciones. Por favor espere...',
	NO_TRANSACTIONS_CLOSE: 'No hay operaciones por cerrar',
	TABLE_TRANSACTION_NUMBER: 'Operación',
	TABLE_TRANSACTION_TYPE: 'Tipo',
	TABLE_TRANSACTION_STATE: 'Estado',
	TABLE_RAFFLES_PER_PAGE: 'Sorteos por página',
	TABLE_ROWS_PER_PAGE: 'Operaciones por Página',
	TABLE_DISPLAYED_ROWS: 'de',
	POS_PRIZE_CREDIT: 'Punto de Venta Premio Crédito',
	POS_PRIZE_EXPIRED: 'Punto de Venta Premio Expirado',

	/* STATE */
	CLOSURE_STATE: 'Cerrada',
	SUCCESS_STATE: 'Aceptada',
	CANCEL_STATE: 'Cancelada',
}

// eslint-disable-next-line import/prefer-default-export
export { APP_TEXTS };
