/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
// User Profile
import React from 'react';
import {
	List,
	Divider,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	Drawer,
} from '@material-ui/core';

import { AccountCircle } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserData, clearUserData, selectOperator } from '../redux/action_types';
import APP_CONFIG from '../config/app.config';
import { APP_TEXTS } from '../config/app.texts';

// Definition
class UserProfile extends React.Component {
	constructor(props) {
		super(props);

		this.userData = {};
		this.state = {
			menuOpen: false,
		};
	}

	openMenu = () => {
		this.setState({
			menuOpen: true,
		});
	};

	closeMenu = () => {
		this.setState({
			menuOpen: false,
		});
	};

	logOut = () => {
		this.closeMenu();

		// Clear local storage
		localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');

		// Update store
		this.props.clearUserData();
	};

	closeTransactions = () => {
		const path = '/closeTransactions';
		if (path) {
			this.closeMenu();
			this.props.history.push(path);
		}
	};

	render() {
		// Check login state
		this.userData = this.props.userData;
		const { firstName, email, logged, operatorName, pointsOfSale } = this.userData;

		return (
			<div className="UserProfile">
				<div className="User">
					{logged && (
						<div className="UserDetails">
							<AccountCircle fontSize="large" />
							<div className="UserName">{firstName}</div>
							<MenuIcon className='Menu' onClick={this.openMenu} fontSize="large" />
						</div>
					)}
				</div>
				<Drawer
					id="MenuFloating"
					anchor="right"
					open={this.state.menuOpen}
					onClose={this.closeMenu}
					className="MenuFloating"
				>
					<List>
						<div
							className="MenuClose"
							onClick={this.closeMenu}
							role="button"
							tabIndex="0"
							aria-label="close Menu"
						>
							<CloseIcon className="closeIcon" />
						</div>
						<ListItem>
							<div className="UserAvatar">
								<Avatar alt="User Profile" className="AvatarPic">
									<AccountCircle fontSize="large" />
								</Avatar>

								<div className="UserName">{firstName}</div>
								<div className="Email">{email}</div>
							</div>
						</ListItem>
						<ListItem className="Section">
							<ListItemText primary={APP_TEXTS.MESSAGE_OPERATOR} className="SectionTitle" />
							<ListItemText primary={operatorName} className="SectionName" />
						</ListItem>
						<Divider className="Divider" />
						{(pointsOfSale && pointsOfSale.length > 0)
							&& (
								<>
									<ListItem className="Section">
										<ListItemText primary={APP_TEXTS.MESSAGE_MY_POS} className="SectionTitle" />
									</ListItem>
									<ListItem className="SectionPos">
										{pointsOfSale.map((sale) => (
											<ListItemText primary={sale.name} className="SectionPosName" />
										))}
									</ListItem>
									<Divider className="Divider" />
									<ListItem
										button
										onClick={() => {
											this.closeTransactions();
										}}
									>
										<ListItemAvatar>
											<AssessmentIcon />
										</ListItemAvatar>
										<ListItemText primary={APP_TEXTS.CLOSE_TRANSACTIONS} />
									</ListItem>
								</>
							)
						}
						<Divider className="Divider" />
						<ListItem
							button
							onClick={() => {
								this.logOut();
							}}
						>
							<ListItemAvatar>
								<ExitToAppIcon />
							</ListItemAvatar>
							<ListItemText primary={APP_TEXTS.MESSAGE_LOGOUT} />
						</ListItem>
						<Divider className="Divider" />
					</List>
				</Drawer>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

const mapDispatchToProps = {
	setUserData,
	clearUserData,
	selectOperator,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile));
