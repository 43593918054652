import React from 'react';
import { connect } from 'react-redux';
import { Paper, Grid, GridListTile, GridListTileBar, Box, IconButton, Dialog, DialogTitle, Typography, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { setUserData, clearUserData } from '../redux/action_types';
import { APP_TEXTS } from '../config/app.texts';
import APP_CONFIG from '../config/app.config';

const errorDialog = {
	background: 'rgb(201,28,123)',
	backgroundImage: 'linear-gradient(0deg, rgba(201,28,123,1) 0%, rgba(228,58,43,1) 100%)',
	padding: '4% ​4% 2% 4%',
	color: '#ffffff',
};

const iconTitle = {
	fontSize: '3rem',
};

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openDialog: false,
			styleDialog: errorDialog,
			features: {},
			messageDialog: APP_TEXTS.SESSION_EXPIRED_MESSAGE,
			titleDialog: APP_TEXTS.SESSION_EXPIRED,
		}
		this.sessionTimeoutId = null;
	}

	componentDidMount() {
		this.validateSession();
	}

	goToPage = (e) => {
		const page = e.currentTarget.getAttribute('link');
		const { history } = this.props;

		if (page) {
			history.push(page);
		}
		return false;
	};

	showDialog = () => {
		this.setState({
			openDialog: true,
	 	});

		 this.sessionTimeoutId = setTimeout(() => {
			this.logOut();
		 }, 8000);
	};

	hideDialog = () => {
		clearTimeout(this.sessionTimeoutId);
		this.sessionTimeoutId = null;
		this.logOut();
	};

	logOut = () => {
		const { props } = this;
		 // Clear local storage and Update store
		 localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');
		 props.clearUserData();
	};
	
	optionsMenu =() => {
		const { userData } = this.props;
		const features = userData.operatorFeatures;
		features.report = true;
		this.setState({ features })
	};
	
	async validateSession() {
		// Check if user is loaded or not
		const { userData } = this.props;

		if (userData && userData.token) {
			this.optionsMenu();

			let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
			requestUrl += APP_CONFIG.API_ENDPOINT_VALIDATE;
			requestUrl += `?hash=${new Date().getTime()}`;

			const headers = new Headers({
				'Content-Type': 'application/json',
				'Authorization': `JWT ${userData.token}`,
			});

			await fetch(requestUrl, {
				method: 'POST',
				headers,
			})
				.then((response) => {
					switch (response.status) {
						case 200:
						case 400:
							// VALID RESPONSE
							return response;
						case 401:
							// Unauthorized access
							this.showDialog();
							break;
						default:
							console.log('Error');
							break;
					}
					return false;
				})
				.then((response) => response.json())
				.then((resp) => {
					if (resp && !resp.logged_in) {
						this.showDialog();
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	render() {
		const { openDialog, styleDialog, titleDialog, features, messageDialog } = this.state;
		return (
			<Paper className="Home Main no-border" variant="elevation" elevation={12}>
				<Grid container className="MainGrid">
					<Grid item xs={6} className="TopGrid">
						<GridListTile
							key="1"
							name="buy_credit"
							className="Card MousePointer h100"
							elevation={4}
							link="/credits"
							onClick={this.goToPage}
						>
							<Box className="CardSelector" />
							<Box
								className="CardImage"
								style={{
									backgroundImage: `url('./images/buy_credit${(features.buy_credit ? "" : "_disable")}.jpg')`,
								}}
							/>
							<GridListTileBar
								title={APP_TEXTS.BUY_CREDIT}
								actionIcon={
									<IconButton>
										<AddIcon
											className={`LargeIcon ${(features.buy_credit ? "redGradient" : "greyGradient")}`}
										/>
									</IconButton>
								}
							/>
						</GridListTile>
					</Grid>				
					<Grid item xs={6} className="TopGrid">
						<GridListTile
							key="2"
							name="withdraw_credit"
							className="Card MousePointer h100"
							elevation={4}
							link="/cashout"
							onClick={this.goToPage}
						>
							<Box className="CardSelector" />
							<Box
								className="CardImage"
								style={{
									backgroundImage: `url('./images/withdraw_credit${(!features.withdraw_credit ? "" : "_disable")}.jpg')`,
								}}
							/>
							<GridListTileBar
								title={APP_TEXTS.CREDIT_WITHDRAWAL}
								actionIcon={
									<IconButton>
										<AddIcon
											className={`LargeIcon ${(!features.withdraw_credit ? "redGradient" : "greyGradient")}`}
										/>
									</IconButton>
								}
							/>
						</GridListTile>
					</Grid>
					<Grid item xs={6} className="BottomGrid">
						<GridListTile
							key="4"
							name="report"
							className="Card MousePointer h100"
							elevation={4}
							link="/report"
							onClick={this.goToPage}
						>
							<Box className="CardSelector" />
							<Box
								className="CardImage"
								style={{
									backgroundImage: "url('./images/balances.jpg')",
								}}
							/>
							<GridListTileBar
								title={APP_TEXTS.BALANCES}
								actionIcon={
									<IconButton>
										<AddIcon className="LargeIcon redGradient" />
									</IconButton>
								}
							/>
						</GridListTile>
					</Grid>
					<Grid item xs={6} className="BottomGrid">
						<GridListTile
							key="4"
							name="closeTransactions"
							className="Card MousePointer h100"
							elevation={4}
							link="/closeTransactions"
							onClick={this.goToPage}
						>
							<Box className="CardSelector" />
							<Box
								className="CardImage"
								style={{
									backgroundImage: "url('./images/close_operations.jpg')",
								}}
							/>
							<GridListTileBar
								title={APP_TEXTS.CLOSE_TRANSACTIONS}
								actionIcon={
									<IconButton>
										<AddIcon className="LargeIcon redGradient" />
									</IconButton>
								}
							/>
						</GridListTile>
					</Grid>
				</Grid>
				<Dialog
					disableBackdropClick
					disableEscapeKeyDown
					maxWidth="xs"
					fullWidth
					aria-labelledby="confirmation-dialog-title"
					open={openDialog}
				>
					<DialogTitle style={styleDialog}>
						<Grid style={{ display: 'flex', margin: '0%'}}>
							<Grid xs={2}>
								<LockRoundedIcon style={iconTitle} />
							</Grid>
							<Grid xs={10}>
								<Typography
									variant="h6"
									style={{ paddingTop: '2%' }}
								>
									{titleDialog}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContentText style={{ padding: '6%', margin: '0% 0% 6% 0%' }}>
						<Typography variant="h6" component="h6" gutterBottom style={{ color: '#000000' }}>
							{messageDialog}
						</Typography>
					</DialogContentText>
					<DialogActions>
						<Button
							className="Button redGradient"
							onClick={this.hideDialog}
							color="default"
						>
							{APP_TEXTS.ACCEPT}
						</Button>
					</DialogActions>
				</Dialog>
			</Paper>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

const mapDispatchToProps = {
	setUserData,
	clearUserData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
