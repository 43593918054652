/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
// React
import React from 'react';
import classNames from 'classnames';
// Redux and Router
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Material UI and Icons
import {
	Button,
	Paper,
	Grid,
	GridListTile,
	GridListTileBar,
	Box,
	Card,
	CardContent,
	Typography,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Collapse,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	CircularProgress,
	ThemeProvider,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// Settings, Utils Functions, Language
import APP_CONFIG from '../config/app.config';
import { APP_UTILS } from '../config/app.utils';
import { APP_TEXTS } from '../config/app.texts';
// Custom theme
import theme from '../Components/ColorTheme';
import DialogImportantInfo from '../Components/DialogImportantInfo';

const styleBigContainer = {
	display: 'flex',
	margin: '1% 1%',
};

const styleTotalCredit = {
	display: 'flex',
	flexDirection: 'column',
	margin: 'auto',
	paddingBottom: 0,
	textAlign: 'center',
};

const styleConfirmBtn = {
	display: 'flex',
	flexDirection: 'column',
	margin: '0% 0% 2% 0%',
	textAlign: 'center',
};

const styleSelect = {
	minWidth: '20%',
	maxWidth: '20%',
	marginRight: '1%',
};

const styleFlexRow = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	width: '100%',
};

const styleTotalColumn = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	borderRadius: '15px',
	boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
};

const bottomBar = {
	padding: '0',
	display: 'flex',
	justifyContent: 'space-between',
};

const successDialog = {
	background: 'rgba(168, 177, 43, 1)',
	backgroundImage: 'linear-gradient(90deg, rgba(168, 177, 43, 1) 0%, rgba(23, 152, 0, 1) 100%)',
	padding: '4% ​4% 2% 4%',
	color: '#ffffff',
};

const cancelDialog = {
	background: 'rgb(201,28,123)',
	backgroundImage: 'linear-gradient(0deg, rgba(201,28,123,1) 0%, rgba(228,58,43,1) 100%)',
	padding: '4% ​4% 2% 4%',
	color: '#ffffff',
};

const iconTitle = {
	fontSize: '3rem',
};

class BuyCredits extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: '',
			buyInProcess: false,
			doc: '',
			email: '',
			messageDialog: '',
			openDialog: false,
			openDialogImportantInfo: false,
			styleDialog: successDialog,
			titleDialog: '',
			typeDoc: 'DNI',
			userId: '',
			validationInProcess: false,
			error: {
				type: 'info',
				message: '',
			},
		};

		this.buyCredit = this.buyCredit.bind(this);
		this.validateUser = this.validateUser.bind(this);
		this.handleDialogImportantOk = this.handleDialogImportantOk.bind(this);
	}

	handleDialogImportantOk() {
		this.setState({ openDialogImportantInfo: false });
	}

	goToHome = () => {
		const path = '/';
		return this.props.history.push(path);
	};

	addCreditKey = (e) => {
		if (e.key && ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) || (e.keyCode === 110)) {
			let newAmount = this.state.amount;
			newAmount = newAmount.concat(e.key);
			this.setState({ amount: newAmount });
		} else if (e.keyCode === 8 && this.state.amount.length) {
			let newAmount = this.state.amount;
			newAmount = newAmount.slice(0, -1);
			this.setState({ amount: newAmount });
		}
	}

	userBelongsToMyAgency = (memberPos) => {
		if (memberPos !== null) {
			const { pointsOfSale } = this.props.userData;
			const belongsToMyAgency = pointsOfSale.filter((sale) => sale.id === memberPos.id);
			
			this.setState({ openDialogImportantInfo: !belongsToMyAgency.length });
		}
	}

	handleDocument = (e) => {
		this.clearError();
		const newDoc = e.target.value;

		if (Number(newDoc) >= 0) {
			if (newDoc) {
				this.setState({ doc: newDoc });
			} else {
				this.setState({ doc: '' });
			}
		} else {
			this.setMessage('error', APP_TEXTS.REQUIRED_INVALID_DOC);
		}
	};

	changeEmail = (e) => {
		if (e.target.value) {
			this.setState({ email: e.target.value });
		} else {
			this.setState({ email: '' });
		}
	};

	addCredit = (e) => {
		const newAmount = e.currentTarget.getAttribute('value');
		const { amount } = this.state;
		const sum = Number(amount) + Number(newAmount);

		this.setState({ amount: String(sum) });
	}

	deleteCredit = () => {
		this.setState({ amount: '' });
		document.getElementById('credit').value = '';
	}

	onKeyPress = (e) => {
		// Get input field
		switch (e.target.id) {
			case 'doc':
				if (e.key === 'Enter' && this.state.doc !== '') {
					this.validateUser();
				}
				break;

			default:
				break;
		}
	};

	processErrors = (errorCode) => {
		// Check for errors
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}
	};

	processResult = (resp) => {
		const state = resp.transaction_state;

		switch (state) {
			case 'TS_SUCCESS':
				this.setState({ 
					messageDialog: APP_TEXTS.SUCCESS_TRANSACTION,
					titleDialog: APP_TEXTS.SUCCESS_TITLE_MESSAGE,
					styleDialog: successDialog,
					openDialog: true,
					buyInProcess: false,
				});
				break;

			case 'TS_CANCEL':
				this.setState({ 
					messageDialog: APP_TEXTS.CANCEL_TRANSACTION,
					titleDialog: APP_TEXTS.CANCEL_TITLE_MESSAGE,
					styleDialog: cancelDialog,
					openDialog: true,
					buyInProcess: false,
				});
				break;

			case 'TS_CLOSE':
				this.setState({ 
					messageDialog: APP_TEXTS.CLOSURE_TRANSACTION,
					titleDialog: APP_TEXTS.SUCCESS_TITLE_MESSAGE,
					styleDialog: successDialog,
					openDialog: true,
					buyInProcess: false,
				});
				break;

			default:
				this.setMessage('error', APP_TEXTS.UNEXPECTED_ERROR);
				break;
		}
	};

	setMessage = (type, message) => {
		if (type && message) {
			// Set Error
			this.setState({
				error: { type, message },
			});
		}
	};

	handleDialogOk = () => {
		this.setState({ openDialog: false });
		this.clearData();
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	clearData = () => {
		this.setState({
			amount: '',
			email: '',
			typeDoc: 'DNI',
			doc: '',
			userId: '',
			openDialog: false,
			openDialogImportantInfo: false,
			messageDialog: '',
			validationInProcess: false,
			buyInProcess: false,
			styleDialog: successDialog,
			error: {
				type: 'info',
				message: '',
			},
		});
	};

	handleChangeSelect = (e) => {
		if (e.target.value) {
			this.setState({ typeDoc: e.target.value });
		}
	};

	async validateUser() {
		// Double check that the login process is not already running
		if (this.state.validationInProcess) {
			return;
		}

		if (!this.state.doc) {
			this.setMessage('error', APP_TEXTS.REQUIRED_DOC);
			return;
		}

		this.clearError();
		this.setState({
			validationInProcess: true,
			email: '',
		});

		const { token } = this.props.userData;
		const { typeDoc, doc } = this.state;

		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_GET_INFO_PERSONAL;

		const params = JSON.stringify({
			"type_doc": typeDoc,
			"doc": doc,
		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			headers,
			body: params,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;
				
					case 401:
						// Unauthorized access
						this.props.history.push('/');
						break;
				
					default:
						// Another status, send unexpected error
						this.processErrors(0);
						this.setState({ validationInProcess: false });
						break;
				}
			
				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					this.setState({ validationInProcess: false });
					return;
				}

				if (resp.email) {
					this.setState({
						email: resp.email,
						userId: resp.casino_user_id,
						validationInProcess: false,
					});

					this.userBelongsToMyAgency(resp.member_pos);
				}
			})
			.catch((error) => {
				console.log(`Error al validar el usuario: ${error}`);
			});
	}

	async buyCredit(e) {
		if (this.state.buyInProcess) {
			return;
		}

		if (this.state.amount < 0.5) {
			this.setMessage('warning', APP_TEXTS.REQUIRED_MIN_CREDIT.replace("{amount}", APP_UTILS.formatCurrency(0.5)));
			return;
		}

		if (!this.state.doc) {
			this.setMessage('error', APP_TEXTS.REQUIRED_DOC);
			return;
		}

		if (this.state.email) {
			if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email))) {
				this.setMessage('warning', APP_TEXTS.REQUIRED_INVALID_EMAIL);
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			this.clearError();
			this.setState({
				buyInProcess: true,
			});

			const { token, operatorId } = this.props.userData;
			const amount = Number(this.state.amount);
			const casinoUserId = this.state.userId || this.state.email;

			let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
			requestUrl += APP_CONFIG.API_ENDPOINT_BUY_CREDIT;

			const params = JSON.stringify({
				"type_doc": this.state.typeDoc,
				"doc": this.state.doc,
				"email": this.state.email,
				"casino_user_id": casinoUserId,
				"amount": amount,
				"operator_id": operatorId,
			});
	
			let headers = {};
			headers = new Headers({
				'Content-Type': 'application/json',
				'Authorization': `JWT ${token}`,
			});

			await fetch(requestUrl, {
				method: 'POST',
				headers,
				body: params,
			})
				.then((response) => {
					switch (response.status) {
						case 200:
						case 400:
							// VALID RESPONSE
							return response;
					
						case 401:
							// Unauthorized access
							this.props.history.push('/');
							break;
					
						default:
							// Another status, send unexpected error
							this.processErrors(0);
							this.setState({ buyInProcess: false });
							break;
					}
				
					return false;
				})
				.then((response) => response.json())
				.then((resp) => {
					// Check status for errors
					if (resp.errors && resp.errors.length) {				
						// Get Error code
						const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
						this.processErrors(errorCode);
						this.setState({ buyInProcess: false });
						return;
					}

					if (resp.transaction_number || resp.transaction_state) {
						this.processResult(resp);
					}
				})
				.catch((error) => {
					console.log(`Error al validar el usuario: ${error}`);
				});
		} else {
			this.setMessage('warning', APP_TEXTS.REQUIRED_EMAIL);
		}
	}

	render() {
		const {
			amount,
			typeDoc,
			doc,
			email,
			openDialog,
			openDialogImportantInfo,
			messageDialog,
			styleDialog,
			validationInProcess,
			buyInProcess,
			titleDialog,
		} = this.state;
		
		return (
			<ThemeProvider theme={theme}>
				<Paper
					className="Main InternalPage"
					variant="outlined"
					elevation={12}
				>
					<Grid container className="MainGrid">
						<Grid item xs={12} className="SectionTitle">
							<GridListTile key="1" className="Title">
								<Box
									className="CardImage"
									style={{ backgroundImage: "url('./images/buy_credit.jpg')" }}
								/>
								<GridListTileBar title={APP_TEXTS.BUY_CREDIT} titlePosition="top" />
							</GridListTile>
						</Grid>
						<Grid
							item
							xs={12}
							className="SectionBody"
							style={{ display: 'flex', alignItems: 'center', marginBottom: '5%' }}
						>
							<Paper className="Body mh100" elevation={0}>
								<Grid
									container
									xs={12}
									style={{ marginTop: '1%' }}
									spacing={1}
								>
									<Grid
										xs={12}
										md={7}
									>
										<Card style={styleBigContainer}>
											<CardContent className="w100">
												<Typography
													variant="h6"
													component="h6"
													style={{ paddingBottom: '1.5%' }}
												>
													{APP_TEXTS.PLAYER_PERSONAL_DATA}
												</Typography>
												<Box>
													<FormControl variant="outlined" style={styleSelect}>
														<InputLabel id="labelDocType">Tipo</InputLabel>
														<Select
															labelId="labelSelectDocType"
															id="selectDocType"
															value={typeDoc}
															onChange={this.handleChangeSelect}
															label="Tipo Doc."
														>
															<MenuItem value="">
																<em>--</em>
															</MenuItem>
															<MenuItem value="DNI">{APP_TEXTS.DNI_LABEL}</MenuItem>
															<MenuItem value="PASS">
																{APP_TEXTS.PASS_LABEL}
															</MenuItem>
															<MenuItem value="LC">
																{APP_TEXTS.LC_LABEL}
															</MenuItem>
															<MenuItem value="LE">
																{APP_TEXTS.LE_LABEL}
															</MenuItem>
														</Select>
													</FormControl>
													<TextField
														id="doc"
														label="Nro. Documento"
														variant="outlined"
														value={doc}
														required
														autoComplete="off"
														onKeyPress={this.onKeyPress}
														onChange={this.handleDocument}
														style={{ width: '30%', marginRight: '1%' }}
													/>
													<Box style={{ display: 'inline-block', marginTop: '1%' }}>
														<Button
															className="Button ButtonHigh greenGradient"
															onClick={this.validateUser}
														>
															{validationInProcess && <CircularProgress style={{ width: '25%', height: '25%', color: '#fff' }} />}
															{!validationInProcess && APP_TEXTS.BTN_SEARCH}
														</Button>
													</Box>
												</Box>
												<Box style={{ marginTop: '1.5%' }}>
													<TextField
														id="email"
														label="Correo electrónico"
														variant="outlined"
														value={email}
														autoComplete="off"
														onChange={this.changeEmail}
														fullWidth
													/>
												</Box>
											</CardContent>
										</Card>
										<Card style={styleBigContainer}>
											<CardContent className="w100">
												<Box style={styleFlexRow}>
													<Grid
														xs={4}
													>
														<Typography variant="h6" component="h6">{APP_TEXTS.CREDIT_TO_LOAD}</Typography>
														<TextField
															id="credit"
															label="Ingresar Crédito"
															variant="outlined"
															value={amount}
															required
															style={{ width: '90%', margin: '2% 0px' }}
															onKeyUp={this.addCreditKey}
														/>
													</Grid>
													<Grid
														xs={8}
														style={{ textAlign: 'center' }}
													>
														<Button value="0.5" className="btnCredits" onClick={this.addCredit}>+ $0.5</Button>
														<Button value="1" className="btnCredits" onClick={this.addCredit}>+ $1</Button>
														<Button value="5" className="btnCredits" onClick={this.addCredit}>+ $5</Button>
														<Button value="10" className="btnCredits" onClick={this.addCredit}>+ $10</Button>
														<Button value="20" className="btnCredits" onClick={this.addCredit}>+ $20</Button>
														<Button
															className="Button btnDeleteCredits"
															onClick={this.deleteCredit}
														>
															{APP_TEXTS.BTN_DELETE}
														</Button>
													</Grid>
												</Box>
											</CardContent>
										</Card>
									</Grid>
									<Grid
										xs={12}
										md={5}
										style={styleTotalColumn}
									>
										<Box justifyContent="center">
											<Card style={styleBigContainer} elevation={0}>
												<CardContent style={styleTotalCredit}>
													<Typography variant="h6" component="h6">{APP_TEXTS.TOTAL_CREDIT}</Typography>
													<Typography variant="h1">{
														APP_UTILS.formatCurrency(amount)}
													</Typography>
												</CardContent>
											</Card>
											<Card elevation={0}>
												<CardContent style={styleConfirmBtn}>
													<Button
														className={
															classNames({
																Button,
																greenGradient: (amount > 0),
																greyGradient: (amount === 0 || amount === ''),
															})
														}
														disabled={amount === 0 || amount === ''}
														style={{ padding: '1.2em 0' }}
														onClick={this.buyCredit}
													>
														{buyInProcess && <CircularProgress style={{ width: '7%', height: '7%', color: '#fff' }} />}
														{!buyInProcess && 'Confirmar Compra'}
													</Button>
												</CardContent>
											</Card>
										</Box>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						<Grid
							xs={12}
							className="SectionActions"
						>
							<Paper className="Actions h100" elevation={0} style={bottomBar}>
								<Collapse
									in={this.state.error.message !== ''}
									style={{ width: '100%'}}
								>
									<Alert
										className="Alert"
										onClose={this.clearError}
										severity={this.state.error.type}
										style={{ padding: '0px 16px', margin: 'auto' }}
									>
										{this.state.error.message}
									</Alert>
								</Collapse>
								<Button
									color="primary"
									className="Button ButtonHigh redGradient"
									onClick={this.goToHome}
								>
									{APP_TEXTS.GO_TO_MENU}
								</Button>
							</Paper>
						</Grid>
					</Grid>
					<Dialog
						disableBackdropClick
						disableEscapeKeyDown
						maxWidth="xs"
						fullWidth
						aria-labelledby="confirmation-dialog-title"
						open={openDialog}
					>
						<DialogTitle style={styleDialog}>
							<Grid style={{ display: 'flex', margin: '0%'}}>
								<Grid xs={2}>
									{(titleDialog === APP_TEXTS.SUCCESS_TITLE_MESSAGE) 
										? <CheckCircleOutlineIcon style={iconTitle} />
										: <BlockIcon style={iconTitle} />
									}
								</Grid>
								<Grid xs={10}>
									<Typography
										variant="h6"
										style={{ paddingTop: '3%' }}
									>
										{titleDialog}
									</Typography>
								</Grid>
							</Grid>
						</DialogTitle>
						<DialogContentText style={{ padding: '6%', margin: '0% 0% 6% 0%' }}>
							<Typography variant="h6" component="h6" gutterBottom style={{ color: '#000000' }}>
								{messageDialog}
							</Typography>
						</DialogContentText>
						<DialogActions>
							<Button
								className="Button greenGradient"
								onClick={this.handleDialogOk}
								color="default"
							>
								{APP_TEXTS.ACCEPT}
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						disableBackdropClick
						disableEscapeKeyDown
						maxWidth="xs"
						fullWidth
						aria-labelledby="confirmation-dialog-title"
						open={openDialogImportantInfo}
					>
						<DialogImportantInfo
							handleConfirmDialog={this.handleDialogImportantOk}
						/>
					</Dialog>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

export default withRouter(connect(mapStateToProps)(BuyCredits));
