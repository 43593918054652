import React from 'react';
import 'date-fns';
// Redux and Router
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Lib date
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
// Material IU and Icons
import {
	Box,
	Button,
	Collapse,
	FormControl,
	Grid,
	GridListTile,
	GridListTileBar,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	ThemeProvider,
	Toolbar,
	Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// configurations and Language
import APP_CONFIG from '../config/app.config';
import { APP_TEXTS } from '../config/app.texts';
import { APP_UTILS } from '../config/app.utils';
// Components
import TableTotals from '../Components/tableTotals';
import TabletDetails from '../Components/tableDetails';
import theme from '../Components/ColorTheme';
import ExportToCSV from '../Components/ExportToCsv';

const bottomBar = {
	padding: '0',
	display: 'flex',
	justifyContent: 'space-between',
};

const toolbarBasicBtn = {
	borderRadius: '150px',
	margin: '0px 10px 0px 0px',
};

const toolbarBtnSelected = {
	borderRadius: '150px',
	margin: '0px 10px 0px 0px',
	backgroundColor: '#C91C7C',
	color: '#ffffff',
};

// Definition
class Reports extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			transState: '',
			transType: '',
			serverData: null,
			fromDate: new Date(),
			toDate: new Date(),
			totalsData: [],
			detailsData: [],
			showTotals: false,
			showDetails: false,
			error: {
				type: 'info',
				message: '',
			},
		};

		
		// Get data from props
		this.history = props.history;
		this.userData = props.userData;

		this.itemsPerPage = 10;
		this.dateToday = new Date();
		this.selectedDateMin = this.dateToday;
		this.selectedDateMin.setDate(this.dateToday.getDate() - 30);
		this.selectedDateMax = new Date();

		this.tableTotalsColumns = [
			{
				id: 'operation',
				label: 'Tipo de Operación',
				width: '25%',
				minWidth: '25%',
				align: 'center',
			},
			{
				id: 'Balance',
				label: 'Total',
				width: '25%',
				minWidth: '25%',
				align: 'center',
			},
		];

		this.tableDetailsColumns = [
			{
				id: 'date',
				label: 'Fecha',
				width: '15%',
				minWidth: '15%',
				align: 'center',
			},
			{
				id: 'hora',
				label: 'Hora',
				width: '10%',
				minWidth: '10%',
				align: 'center',
			},
			{
				id: 'transactionNumber',
				label: APP_TEXTS.TABLE_TRANSACTION_NUMBER,
				width: '20%',
				minWidth: '20%',
				align: 'center',
			},
			{
				id: 'typeTransaction',
				label: APP_TEXTS.TABLE_TRANSACTION_TYPE,
				width: '20%',
				minWidth: '20%',
				align: 'center',
			},
			{
				id: 'stateTransaction',
				label: APP_TEXTS.TABLE_TRANSACTION_STATE,
				width: '20%',
				minWidth: '20%',
				align: 'center',
				format: (value) => value.toFixed(2),
			},
			{
				id: 'value',
				label: 'Valor',
				width: '15%',
				minWidth: '15%',
				align: 'center',
			},
		];

		this.transactions_type = [
			['BUY_CARD'],
			['PAY_PRIZE'],
			['BUY_CREDIT'],
			['CREDIT_WITHDRAWAL'],
			['COMMISSION'],
			['BUY_CARD', 'PAY_PRIZE', 'BUY_CREDIT', 'CREDIT_WITHDRAWAL','COMMISSION'],
		];

		this.transactions_state = [
			['TS_SUCCESS'],
			['TS_CLOSE'],
			['TS_CANCEL'],
			['TS_SUCCESS','TS_CLOSE','TS_CANCEL'],
		];

		this.getTransactions = this.getTransactions.bind(this);
	}

	async getTransactions() {
		// Get User Data
		this.setMessage('info', APP_TEXTS.SEARCHING);
		this.setState({ 
			detailsData: [],
			totalsData: [],
		});
		// this.userData = this.props.userData;

		const { transType, transState } = this.state;
		const type = (transType === 'Todas' || transType === '') ? 5 : transType;
		const state = (transState === 'Todos' || transState === '') ? 3 : transState;
		let { fromDate, toDate } = this.state;

		fromDate.setHours(0, 1, 0);
		fromDate = this.calcTime(fromDate);
		toDate.setHours(23, 59, 59);
		toDate = this.calcTime(toDate);

		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_REPORT_TRANSACTION;
		const params = JSON.stringify({
			"date_from": fromDate,
			"date_to": toDate,
			"operator_id": this.userData.operatorId,
			"transaction_type": this.transactions_type[type],
			"transaction_state": this.transactions_state[state],

		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${this.userData.token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.goToHome();
						break;

					default:
						this.processErrors(0);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}

				if (resp.transactions && resp.totals) {
					this.clearError();
					this.setState({
						serverData: resp,
					});

					this.processResult();
				} else if(resp.message) {
					this.setMessage('info', resp.message);
				} else if(resp.transactions && resp.transactions.length === 0) {
					this.setMessage('', APP_TEXTS.NOT_REPORT_FOUND);
					this.setState({ 
						serverData: null,
						detailsData: [],
						totalsData: [],
					 });
				}
			})
			.catch((error) => {
				console.log(error);
				/* this.setState({
					reportMessage: `Error: ${error}`,
				}); */
			});
	}

	processErrors = (errorCode) => {
		// Check for errors
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}
	};

	processResult = () => {
		const { serverData } = this.state;
		const { transactions } = serverData;
		const parsedData = [];
		const totals = [];
		let sortedData = [];

		if (serverData.totals) {
			Object.entries(serverData.totals).forEach(([key, value]) => {
				const elem = {
					operation: this.parseTextFromApi(key),
					total: value,
				};

				totals.push(elem);
			});
		}

		// eslint-disable-next-line no-plusplus
		for (let index = 0; index < transactions.length; index++) {
			const trans = transactions[index];
			const created = new Date(trans.created);

			const year = new Date(created).getFullYear();
			let month = new Date(created).getUTCMonth() + 1;
			let day = new Date(created).getUTCDate();
			month = ( month >= 10) ? month : `0${month}`;
			day = ( day >= 10) ? day : `0${day}`;
			const date = `${year}-${month}-${day}`;

			let h = new Date(created).getHours();
			let m = new Date(created).getMinutes();
			let s = new Date(created).getSeconds();
			h = ( h >= 10) ? h : `0${h}`;
			m = ( m >= 10) ? m : `0${m}`;
			s = ( s >= 10) ? s : `0${s}`;
			const time = `${h}:${m}:${s}`;

			const data = {
				date,
				time,
				id: trans.transaction_number,
				type: this.parseTextFromApi(trans.transaction_type),
				state: this.parseTextFromApi(trans.transaction_state),
				value: trans.amount,
			};

			parsedData.push( data );
		}

		if (parsedData.length) {
			sortedData = parsedData.sort((a, b) => {
				const aDate = new Date(a.date).getTime();
				const bDate = new Date(b.date).getTime();
				return aDate - bDate;
			});
		}

		this.setState({
			detailsData: sortedData,
			totalsData: totals,
		});

		this.setShowTotals()
	};

	parseTextFromApi = (text) => {
		let newText = '';

		switch (text) {
			case 'BUY_CARD':
				newText = APP_TEXTS.BUY_CARD;
				break;

			case 'PAY_PRIZE':
				newText = APP_TEXTS.PAY_PRIZE;
				break;

			case 'BUY_CREDIT':
				newText = APP_TEXTS.BUY_CREDIT;
				break;

			case 'CREDIT_WITHDRAWAL':
				newText = APP_TEXTS.CREDIT_WITHDRAWAL
				break;

			case 'COMMISSION':
				newText = APP_TEXTS.COMMISSION
				break;

			case 'TS_SUCCESS':
				newText = APP_TEXTS.SUCCESS_STATE;
				break;

			case 'TS_CLOSE':
				newText = APP_TEXTS.CLOSURE_STATE;
				break;

			case 'TS_CANCEL':
				newText = APP_TEXTS.CANCEL_STATE;
				break;

			default:
				newText = text;
				break;
		}

		return newText;
	};

	setMessage = (type, message) => {
		if (type && message) {
			this.setState({
				error: { type, message },
			});
		}
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	handleFromDateChange = (e) => {
		const fromDate = e;
		fromDate.setHours(0, 1, 0);

		this.setState({ fromDate });
	};

	handleToDateChange = (e) => {
		const toDate = e;
		toDate.setHours(23, 59, 59);

		this.setState({ toDate });
	}

	calcTime = (date) => {
		const offset = -3 * 2;
		const hourByMilli = 3600000;
		const millisecondsInMinute = 60000;
	
		const utc = date.getTime() + (date.getTimezoneOffset() * millisecondsInMinute);
		const newDateWithOffset = new Date(utc + (hourByMilli * offset));
	
		return newDateWithOffset.toISOString();
	};

	handleSelectState = (e) => {
		if (e.target.value) {
			this.setState({ transState: e.target.value });
		}
	};

	handleSelectType = (e) => {
		if (e.target.value) {
			this.setState({ transType: e.target.value });
		}
	};

	goToHome = () => {
		const path = '/';
		this.history.push(path);
	};

	setShowTotals = () => {
		const { serverData } = this.state;

		this.setState({
			showTotals: true,
			showDetails: false,
		});

		if (!serverData) { this.getTransactions(); }
	};

	setShowDetails = () => {
		const { serverData } = this.state;

		this.setState({
			showDetails: true,
			showTotals: false,
		});

		if (!serverData) { this.getTransactions(); }
	};

	exportToCSV = () => {
		const { detailsData } = this.state;
		if (detailsData.length) {
			const header = [];
			this.tableDetailsColumns.map((row) => header.push(row.label));

			ExportToCSV(header, detailsData);
		}
	}

	render() {
		const {
			detailsData,
			error,
			fromDate,
			showDetails,
			showTotals,
			toDate,
			totalsData,
			transState,
			transType,
		} = this.state;
		const dataTotalsTable = {
			tableColumns: this.tableTotalsColumns,
			rowData: totalsData,
		};
		const dataDetailsTable = {
			tableColumns: this.tableDetailsColumns,
			rowData: detailsData,
		};
		return (
			<ThemeProvider theme={theme}>
				<Paper className="Main InternalPage" variant="outlined" elevation={12}>
					<Grid container className="MainGrid h100">
						<Grid item xs={12} className="SectionTitle">
							<GridListTile key="1" className="Title">
								<Box
									className="CardImage"
									style={{
										backgroundImage: "url('./images/balances.jpg')",
									}}
								/>
								<GridListTileBar title="Reportes" titlePosition="top" />
							</GridListTile>
						</Grid>
						<Grid item xs={12} className="SectionBody">
							<Paper className="Body mh100" elevation={0}>
								<Grid container className="BodyTitle">
									<Grid
										item
										xs={12}
										sm={6}
										style={{ display: 'flex', justifyContent: 'flex-start', margin: '2% 0px 0px 0px' }}
									>
										<Typography variant="subtitle2">
											Filtrar<br />
											búsqueda
										</Typography>
										<FormControl variant="outlined" className='dropDownReports'>
											<InputLabel id="labelType">Operación</InputLabel>
											<Select
												labelId="labelSelectType"
												id="selectType"
												value={transType}
												onChange={this.handleSelectType}
												label="Tipo de Operación."
											>
												<MenuItem value="">
													<em>--</em>
												</MenuItem>
												<MenuItem value="0">Compra de Cartones</MenuItem>
												<MenuItem value="1">Pago de Premios</MenuItem>
												<MenuItem value="2">Compra de crédito</MenuItem>
												<MenuItem value="3">Retiro de Dinero</MenuItem>
												<MenuItem value="4">Comisión</MenuItem>
												<MenuItem value="Todas">Todas</MenuItem>
											</Select>
										</FormControl>
										<FormControl variant="outlined" className='dropDownReports'>
											<InputLabel id="labelState">Estado</InputLabel>
											<Select
												labelId="labelSelectState"
												id="selectState"
												value={transState}
												onChange={this.handleSelectState}
												label="Estado"
											>
												<MenuItem value="">
													<em>--</em>
												</MenuItem>
												<MenuItem value="0">Aceptada</MenuItem>
												<MenuItem value="1">Cerrada</MenuItem>
												<MenuItem value="2">Cancelada</MenuItem>
												<MenuItem value="Todos">Todos</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid
										xs={12}
										sm={6}
										style={{ display: 'flex', margin: '2% 0px 0px 0px' }}
									>
										<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
											<Grid container justify="center">
												<DatePicker
													disableFuture
													autoOk
													inputVariant="outlined"
													label="Seleccione Desde Fecha"
													format="dd/MM/yyyy"
													orientation="landscape"
													id="fromDate"
													okLabel=''
													cancelLabel=''
													emptyLabel=''
													invalidLabel=''
													invalidDateMessage=''
													value={fromDate}
													onChange={this.handleFromDateChange}
													style={{ margin: '0px 2%' }}
												/>
											</Grid>
										</MuiPickersUtilsProvider>
										<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
											<Grid container justify="center">
												<DatePicker
													disableFuture
													autoOk
													inputVariant="outlined"
													label="Seleccione Hasta Fecha"
													format="dd/MM/yyyy"
													orientation="landscape"
													id="toDate"
													okLabel=''
													cancelLabel=''
													emptyLabel=''
													invalidLabel=''
													invalidDateMessage=''
													value={toDate}
													onChange={this.handleToDateChange}
													style={{ margin: '0px 2%' }}
												/>
											</Grid>
										</MuiPickersUtilsProvider>
										<Box style={{ margin: 'auto', paddingTop: '1%' }}>
											<Button
												className="Button ButtonHigh greenGradient"
												onClick={this.getTransactions}
											>
												Generar
											</Button>
										</Box>
									</Grid>
								</Grid>
								<Grid
									className='ContainerBodyReport'
								>
									{(detailsData.length > 0) &&
										<Toolbar>
											<Box>
												<Button
													variant="contained"
													size="small"
													style={((showTotals) ? toolbarBtnSelected : toolbarBasicBtn)}
													onClick={this.setShowTotals}
													disabled={(totalsData.length === 0)}
												>
													Ver Resumen
												</Button>
											</Box>
											<Box>
												<Button
													variant="contained"
													size="small"
													style={((showDetails) ? toolbarBtnSelected : toolbarBasicBtn)}
													onClick={this.setShowDetails}
													disabled={(detailsData.length === 0)}
												>
													Ver Detalle
												</Button>
											</Box>
											<Box>
												<Button
													variant="contained"
													size="small"
													style={toolbarBasicBtn}
													onClick={this.exportToCSV}
													disabled={(detailsData.length === 0)}
												>
													Descargar CSV
												</Button>
											</Box>
										</Toolbar>
									}									
									{(showTotals && totalsData.length > 0) && 
										/* Viewing totals data in the table */
										<TableTotals data={dataTotalsTable} />
									}
									{(showDetails && detailsData.length > 0) &&
										/* Viewing detailed data in the table */
										<TabletDetails data={dataDetailsTable} />
									}
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12} className="SectionActions">
							<Paper className="Actions h100" elevation={0} style={bottomBar}>
								<Collapse
									in={error.message !== ''}
									style={{ width: '100%'}}
								>
									<Alert
										className="Alert"
										onClose={this.clearError}
										severity={error.type}
										style={{ padding: '0px 16px', margin: 'auto' }}
									>
										{error.message}
									</Alert>
								</Collapse>
								<Button className="Button ButtonHigh redGradient" onClick={this.goToHome}>
									{APP_TEXTS.GO_TO_MENU}
								</Button>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

export default withRouter(connect(mapStateToProps)(Reports));
// export default Reports;
