/* eslint-disable prefer-object-spread */
import {
	SET_USER_DATA,
	CLEAR_USER_DATA,
	SELECT_OPERATOR,
	SET_RAFFLE_DATA,
	CLEAR_RAFFLE_DATA,
} from './action_types';

const initialState = {
	userData: {
		logged: false,
		firstName: '',
		lastName: '',
		email: '',
		operators: [],
		operatorId: 0,
		operatorName: '',
		operatorFeatures: {
			buy_card: true,
			buy_credit: true,
			pay_card: true,
			withdraw_credit: true,
			report: true,
		},
		pointsOfSale: [],
		prizesData: [],
		token: '',
		firstLogin: false,
	},
	raffleData: {
		all: {},
		selected: {},
		selectedDate: {},
	},
};

const userReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_USER_DATA: {
			const mergeData = { ...state.userData, ...payload };
			return { userData: mergeData };
		}

		case CLEAR_USER_DATA: {
			return { ...state, ...initialState };
		}

		case SELECT_OPERATOR: {
			const operatorPayload = { operatorId: payload.operatorId, operatorName: payload.operatorName };
			const mergeData = { ...state.userData, ...operatorPayload };
			return { userData: mergeData };
		}

		case SET_RAFFLE_DATA: {
			const data = { ...state.raffleData, ...payload };
			const {userData} = state;
			return {
				userData,
				raffleData: data,
			};
		}

		case CLEAR_RAFFLE_DATA: {
			return { ...state.raffleData, ...initialState.raffleData };
		}

		default:
			return state;
	}
};

export default userReducer;
